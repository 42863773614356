@mixin underline {
  content: "";
  height: 2px;
  width: 4rem;
  position: absolute;
  bottom: 0;
}

@mixin section-header {
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 1;
  position: relative;
  padding: 0.75rem 0;
  margin-bottom: 2rem;

  &:after {
    @include underline;
    left: 50%;
    transform: translateX(-50%);
    background-color: $yellow;
  }
}
