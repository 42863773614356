@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&family=Nunito:wght@300;400;700;900&display=swap");
@import "variables";
@import "mixins";
@import "bootstrap";

html,
body {
  scroll-behavior: smooth;
  line-height: 1.33;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
label,
button {
  font-family: "Nunito", sans-serif;
}

label,
button {
  font-weight: 900;
  text-transform: uppercase;
}

.animate {
  opacity: 0;
}

.row {
  z-index: 1;
  position: relative;

  &.row--light-bg {
    background-color: $light;
    z-index: 100;
  }
  & > div {
    max-width: 992px;
  }
}

nav.row {
  background-color: rgba(255, 255, 255, 0.75);
  color: $dark;
  position: fixed;
  z-index: 1000;

  a {
    color: $dark;
    transition: 200ms;

    &:hover {
      color: $yellow;
    }
  }

  .navbar {
    flex-grow: 2;
    .nav-icon {
      width: 150px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    button {
      border: none;
      span {
        color: $dark;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgb(35, 15, 69)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22' /></svg>") !important;

        &:hover {
          color: $yellow;
        }
      }
    }

    .nav-menu {
      flex-grow: 2;
      .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .nav-item {
          margin: 0 1rem;
          a {
            color: $dark;
          }
        }
      }
    }
  }

  .nav-lang {
    min-width: 7rem;
    flex-direction: row;
    align-items: flex-start;

    @media (min-width: #{map-get($grid-breakpoints, "lg")}) {
      position: absolute;
      left: 100%;
    }
    a {
      padding: 0.5rem 1rem;
      color: $dark;

      &.active {
        border-radius: 3rem;
        font-weight: 900;
        color: $dark !important;
      }
    }
  }
}

main {
  h2 {
    text-align: center;
    font-weight: 900;
  }

  header {
    h1 {
      margin: 0;
    }
    img {
      display: block;
      margin: 0 auto;

      @media (max-width: #{map-get($grid-breakpoints, "md") - 1}) {
        max-width: 320px;
      }
    }
  }

  #howItWorks {
    h2 {
      @include section-header;
    }
  }

  #features {
    :nth-child(odd) {
      flex-direction: row-reverse;
    }

    h2 {
      @include section-header;
    }

    img {
      margin: 1rem 0;
      width: 100%;
      height: auto;

      @media (max-width: #{map-get($grid-breakpoints, "sm") - 1}) {
        display: block;
        margin: 2rem auto 1rem;
        max-width: 280px;
      }
    }

    h3 {
      font-family: "Nunito Sans", sans-serif;
      font-size: 1.33rem;
      font-weight: 800;
      margin-bottom: 1.33rem;
    }

    p {
      position: relative;
      margin-bottom: 2rem;
      &:after {
        @include underline;
        left: 0;
      }
    }
  }

  #emailForm {
    position: relative;
    img {
      position: absolute;
      top: -30%;
      left: -3rem;
      width: calc(100% + 6rem);
      max-width: initial;
      height: auto;
    }

    input {
      border: none;
      border-radius: 0;
    }

    #emailMessage {
      padding: 2rem 0 0;
    }
  }
}

footer {
  img {
    display: block;
    max-width: 150px;

    @media (max-width: #{map-get($grid-breakpoints, "md") - 1}) {
      margin: 2rem auto;
    }
  }
}
