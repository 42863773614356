// Colors
$light: #fef9e8;
$gray: #f4f3f6;
$red: #c75d6a;
$blue: #2288fc;
$green: #39b54a;
$yellow: #efc228;
$dark: #230f45;

$theme-colors: (
        "light": $light,
        "primary": $yellow,
        "secondary": $blue,
        "black": $dark,
);

// Body
$body-color: $dark;

// Misc
$box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);

// Typography
$font-family-sans-serif: 'Nunito Sans', sans-serif;

// Button
$btn-border-radius: 50px;
$btn-border-width: 2px;
$btn-font-weight: black;